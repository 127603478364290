import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import * as s from './storyboard.module.scss'

const StoryBoard = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: { extension: {}, relativeDirectory: { eq: "storyboard" } }) {
        edges {
          node {
            id
            base
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)

  const sortNumeric = (data) => {
    return data.sort((a, b) => {
      return a.node.base.replace(/[^0-9]/g, '') - b.node.base.replace(/[^0-9]/g, '')
    })
  }

  return (
    <>
      <div className={s.images}>
        {sortNumeric(data.allFile.edges).map(({ node }) => {
          return <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt="" />
        })}
      </div>
    </>
  )
}

export default StoryBoard
